<template>
  <div class="page-home">
    <!--<div class="home-entry">-->
      <!--<div class="container">-->
        <!--&lt;!&ndash; 左侧分类 &ndash;&gt;-->
        <!--<HomeCategory />-->
        <!--&lt;!&ndash; 轮播图 &ndash;&gt;-->
        <!--<HomeBanner></HomeBanner>-->
      <!--</div>-->
    <!--</div>-->
    <!-- 新鲜好物 -->
    <HomeNew></HomeNew>
    <!-- 人气推荐 -->
    <!--<HomeHot></HomeHot>-->
    <!--&lt;!&ndash; 品牌推荐 &ndash;&gt;-->
    <!--<HomeBrand></HomeBrand>-->
    <!--&lt;!&ndash; 商品区块 &ndash;&gt;-->
    <!--<HomeProduct></HomeProduct>-->
    <!--&lt;!&ndash; 最新专题 &ndash;&gt;-->
    <!--<HomeSpecial></HomeSpecial>-->
  </div>
</template>

<script>
// import HomeCategory from "./components/home-category.vue";
// import HomeBanner from "./components/home-banner.vue";
import HomeNew from "./components/home-new.vue";
// import HomeHot from "./components/home-hot.vue";
// import HomeBrand from "./components/home-brand.vue";
// import HomeProduct from "./components/home-product.vue";
// import HomeSpecial from "./components/home-special.vue";
export default {
  name: "PageHome",
  components: {
    // HomeCategory,
    // HomeBanner,
    HomeNew,
    // HomeHot,
    // HomeBrand,
    // HomeProduct,
    // HomeSpecial
  },
  setup() {}
};
</script>

<style lang="less" scoped></style>
