<template>
  <div class="HomeNew">

      <div class="HomeNew-top">商品列表</div>
      <!-- 面板内容 -->
      <Transition name='fade'  ref="target">
      <div class="good-list" v-if="goods.length">
      <div class="product-cont" v-for="item in goods" :key="item.id">
        <div class="product-img">
        <h2>{{ item.name }}</h2>
        <el-carousel height="250px" width="500px">
          <el-carousel-item v-for="item0 in 1" :key="item0">
            <img :src="item.img" />
          </el-carousel-item>
        </el-carousel>
        </div>
        <div class="product-info">
          <p class="s1">套餐价格：{{ item.price/100 }}元/人民币/件</p>
          <div v-html="item.remark "></div>
          <RouterLink :to="`/product/${item.id}`"><p class="buybtn"><i class="el-icon-caret-right"></i>购买</p></RouterLink>
          <div class="attention"><i class="el-icon-info"></i>注意事项:订单提交成功后，如需要撤销和更改，您可在15分钟内取消订单，
          超过15分钟后将不能通过网站发起退单，需联系4000082688进行反馈.</div>
        </div>
      </div>
      </div>

        <HomeLoading v-else></HomeLoading>
        <!--<HomeSkeleton v-else></HomeSkeleton>-->
      </Transition>

  </div>
</template>

<script>
import { ref } from "vue";
import HomePanel from "./home-panel.vue";
// import HomeSkeleton from "./home-skeleton.vue";
import HomeLoading from "./home-loading.vue";
import {uselazyData} from '@/hooks/index'
import { findAllGoods } from "@/api/home";
export default {
  name: "HomeNew",
  components: { HomeLoading },
  setup() {
    const goods = ref([]);
    findAllGoods().then(data => {
      for (let i in data.data) {
        let item = data.data[i]
        goods.value.push(item)
        item.img = require('../../../assets/images/' + item.picture)
      }
      console.log('load goods ', goods.value)
    })

    const target = ref(null)
    // const goods = uselazyData(target, findAllGoods)
    return { goods, target };
  }
};
</script>

<style lang="less">
.fade {
  &-leave {
    &-active {
      position: absolute;
      width: 100%;
      transition: opacity 0.5s 0.2s;
      z-index: 1;
    }
    &-to {
      opacity: 0;
    }
  }
}
.goods-list {
  display: flex;
  /*justify-content: space-between;*/
  height: 300px;
  padding-bottom: 20px;
  li {
    width: 306px;
    /*height: 406px;*/
    margin-right: 5px;
    border: 1rem solid #f0f9f4;
    .hoverShadow();
    img {
      margin: 10px;
    }
    p {
      font-size: 22px;
      padding: 12px 30px 0 30px;
      text-align: center;
    }
    .price {
      color: @priceColor;
    }
  }
}
 .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
  .el-carousel--horizontal{
    width:500px;
  }
  .el-carousel__button{
    width:5px;
    height:5px;
    border-radius:50%;
  }
  .product-cont{
    display:flex;
    width:800px;
    margin:0 auto;
    padding-top:40px;
  }
  .product-info{
    padding-left:32px;
  }
 .product-info p{
     line-height:25px;
     font-size:14px;
  }
  .product-info .s1{
    padding-bottom:20px;
    padding-top:40px;
  }
.product-img h2{
  font-size:20px;
  text-align:center;
}
.product-img img{
  width:100%;
  height:100%;
}
.product-info .buybtn{
  padding-top:20px;
  font-size:16px;
  color:#e6a23c;
}

.HomeNew-top{
  background:#f07a22;
  height:40px;
  text-align:center;
  color:#fff;
  line-height:40px;
}
.HomeNew{
  height:680px;
  padding-bottom:100px;
  overflow-y: scroll;
}
.attention{
  padding-top:10px;
  font-size:12px;
  color:#a3a3a3;
}
</style>
